import * as React from 'react';
import { SEO, ServiceBlockGroup, Container } from '@components';

import * as s from '../styles/services.module.scss';

function ServicesPage() {
    return (
        <Container>
            <SEO title="Services" />
            <h1 className={s.title}>Home Remodeling Services</h1>
            <p className={s.text}>Bringing any and every aspect of your dream home to reality</p>
            <ServiceBlockGroup />
        </Container>
    );
}

export default ServicesPage;
